<template>
  <div class="safetyCompetitionAll">
    <div class="safetyCompetitionFirst">
      <div class="safetyCompetitionFirst1">
        <h1>安全竞赛</h1>
        <p>
          2018年9月，中央网信办发布了《关于规范促进网络安全竞赛活动的通知》，要求“网络安全竞赛要坚持国家安全和社会效益优先”，“注重以多种形式面向青少年、网络安全从业人员举办知识型、技能型、普及型竞赛”。
        </p>
      </div>
    </div>
    <div class="safetyCompetitionSecond">
      <div class="safetyCompetitionSecondLeft">
        <img src="../../../../assets/img/sofaFW/6.webp" alt="" />
      </div>
      <div class="safetyCompetitionSecondRight">
        <div class="safetyCompetitionSecondRight1">
          <p>
            显然，安全竞赛发展至今，已不仅仅是单纯的竞赛，或者说是为了比赛而比赛，管理部门和社会各界都开始思考，竞赛能给产业乃至国家带来何种实实在在的价值。
          </p>
          <p>那么，比赛到底是为了什么？怎样才算是一场好的网络安全赛事？</p>
        </div>
      </div>
    </div>
    <div class="safetyCompetitionThird">
      <h1>网络安全赛事进化史</h1>
      <div class="safetyCompetitionThird1">
        <div class="safetyCompetitionThird1-1">
          <h2>答题</h2>
          <p>
            指的是CTF（Capture The
            Flag）夺旗赛，这也是国内最早和最常见的竞赛形式。
          </p>
          <p>
            CTF起源于1996年DEFCON全球黑客大会，初衷是网络安全研究者之间的技术切磋。当这些高手云集在一处时，不仅进行技术分享，也有比赛一番的冲动，在较量中亮出自己的拿手绝活，逼出自己压箱底的本领。
          </p>
        </div>
        <div class="safetyCompetitionThird1-1">
          <h2>对抗</h2>
          <p>AWD（Attack With Defense），也叫攻防兼备模式</p>
          <p>
            选手同时具备攻击方和防守方两种角色，对选手的攻击能力、防御能力、团队协作能力及实时的应变能力都是极大的考验，更偏向实战能力的较量。网络安全技术的力量就好像《星球大战》里的原力一样，有光明和黑暗两面，而AWD赛制则在寻找和利用漏洞之外，增加了分析和修复问题的鼓励
          </p>
        </div>
        <div class="safetyCompetitionThird1-1">
          <h2>场景</h2>
          <p>
            即场景实战，是近年来环境、技术以及硬件水平飞速发展之后涌现出来的一种崭新的竞技模式。
          </p>
          <p>
            通过平行仿真技术，对网络结构、安全元件、安全策略乃至业务人员、管理人员的日常工作进行还原，构建网络空间的“朱日和”。正如上文中提到的产业对竞赛的思考，网络安全竞赛发展多年，除了高手切磋以外，产业的需求也渐渐反映在赛事中。
          </p>
        </div>
      </div>
    </div>
    <div class="safetyCompetitionFourth">
      <div class="safetyCompetitionFourth1">
        <div class="safetyCompetitionFourthLeft">
          <p>
            网络安全竞赛从顶尖极客间的擂台，随着数字化的步伐走向了各行各业，吸引越来越多的安全从业者、爱好者参与其中。从技术切磋到技能训练，网络安全赛事正在走向社会，走向行业，驱动国家整体安全能力不断进步。
          </p>
        </div>
        <div class="safetyCompetitionFourthRight">
          <img src="../../../../assets/img/sofaFW/7.webp" alt="" />
        </div>
      </div>
      <p>
        产业需求的推动，是网络安全赛事发展到实景攻防阶段的最根本动力。近两年基于实战场景的赛事越来越多，堪称国内最大规模工业互联网网络安全“大练兵”的“护网杯”，首个基于城市大规模真实场景的“巅峰极客”网络安全技能挑战赛，都是高仿真网络靶场在比赛中的成功应用。“在靶场中的攻防对抗更具实用价值，除了用于常规比赛，靶场还具有日常演练、技术验证、渗透测试、人才培养等多种作用。”
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .safetyCompetitionAll {
    width: 100%;
    .safetyCompetitionFirst {
      background-image: url(https://www.boundaryx.com/wp-content/uploads/2022/05/news.jpeg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 80px 40px;
      .safetyCompetitionFirst1 {
        margin: 0 auto;
      }
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
        color: #fff;
      }
      p {
        text-indent: 1.5rem;
        font-size: 14px;
        color: #51565d;
        line-height: 20px;
        margin-top: 10px;
        color: #fff;
      }
    }
    .safetyCompetitionSecond {
      margin-top: 40px;
      background: #f1f5ff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 40px;
      .safetyCompetitionSecondRight {
        width: 51%;
        margin-left: 40px;
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          color: #51565d;
          line-height: 20px;
          margin-top: 10px;
        }
      }
      .safetyCompetitionSecondLeft {
        img {
          width: 30vw;
          height: 27vh;
        }
      }
    }
    .safetyCompetitionThird {
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      .safetyCompetitionThird1 {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .safetyCompetitionThird1-1 {
          width: 220px;
          border: 3px solid #ccc;
          border-radius: 10px;
          padding: 15px;
          margin-top: 20px;
          h2 {
            text-align: center;
            font-size: 16px;
          }
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            color: #51565d;
            line-height: 20px;
            margin-top: 10px;
          }
        }
        .safetyCompetitionThird1-1:hover {
          background-color: #4c84ff;
          transition: all 0.5s;
          transform: scale(1.1);
        }
      }
    }
    .safetyCompetitionFourth {
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;

      p {
        text-indent: 1.5rem;
        font-size: 14px;
        color: #51565d;
        line-height: 20px;
        margin-top: 10px;
      }
      .safetyCompetitionFourth1 {
        display: flex;
        justify-content: center;
        align-items: center;
        .safetyCompetitionFourthLeft {
          width: 50%;
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            color: #51565d;
            line-height: 20px;
            margin-top: 10px;
          }
        }
        .safetyCompetitionFourthRight {
          img {
            width: 30vw;
            height: 27vh;
          }
        }
      }
    }
  }
}
</style>